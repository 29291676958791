<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes=" table-responsive">
            <file-explorer ref="fileExplorer" :indexFolder="documents" :title="$t('folders')" :root="endpoint" />
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FileExplorer from "@/components/FileExplorer";
import { apiCallList } from '../../mixins'

export default {
    mixins: [apiCallList],
     
    data(){
        return {
            endpoint : "client/documents"+(this.id ? "?where-parent_id="+this.id : ""),
            itemSelected : {},
            options : true,
            documents : null
        }
    },
    props : {
      id : String
    },
    components : {
        FileExplorer
    },    
    methods : {
        loadItems(){
            this.documents = this.data;
            if(this.id){
              this.$refs.fileExplorer.selectFolder = this.id;
              this.$refs.fileExplorer.onLoadBreadcrumb();
            }
        }
    }
}
</script>